.AccountList {
    font-size: 13px;
    margin-left: 8px;
  }

.AccountList h4 {
    margin-bottom: 0px;
    margin-left: 2px;
    margin-top: 25px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
  }

.error {
    padding: 10px;
    font-size: 15px;
    color: #777;
}

.AccountList table {
    width: 100%;
}

.AccountList #accounts {
    font-size: 13px;
    padding: 10px;
}

.AccountList #accounts td {
    padding: 3px;
    padding-left: 8px;
}

.empty-selection {
    text-align: right;
}

.AccountList #empty-button {
    margin-bottom: 5px;
}

