html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}
.App {
  margin-top: 15px;
}

.navbar {
  margin-bottom: 7px;
}

.navbar-left {
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #777;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-selected {
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #06acd6;
  padding-top: 15px;
  padding-bottom: 15px;
}

.App #loader {
  display: block;
  margin-right: 50px;
}

.service-list {
  margin-left: 50px;
}
.Dashboard .lander {
    padding: 80px 0;
    text-align: center;
  }
  
.Dashboard .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Dashboard .lander p {
  color: #999;
}

.Dashboard .ButtonToolbar {
  padding: 11px;
  margin-left: 20%;
  margin-right: 20%;
}

.dropdown-header {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #777;
}

.menu-users {
  margin-left: 10px;
}

.container {
    width: 100%;
    padding: -15px;
}

.AccountFilter {
    font-size: 13px;
    margin-left: 8px;
  }

.AccountFilter td {
    padding: 2px;
}

.AccountFilter h4 {
    margin-bottom: 10px;
    margin-left: 2px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
  }

.AccountFilter #AccountFilterTextFilter {
    max-width: 300px;
    font-size: 13px;
}
.AccountList {
    font-size: 13px;
    margin-left: 8px;
  }

.AccountList h4 {
    margin-bottom: 0px;
    margin-left: 2px;
    margin-top: 25px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
  }

.error {
    padding: 10px;
    font-size: 15px;
    color: #777;
}

.AccountList table {
    width: 100%;
}

.AccountList #accounts {
    font-size: 13px;
    padding: 10px;
}

.AccountList #accounts td {
    padding: 3px;
    padding-left: 8px;
}

.empty-selection {
    text-align: right;
}

.AccountList #empty-button {
    margin-bottom: 5px;
}


.ChartStyle {
    min-height: 120px;
    margin-bottom: 5px;
}

.ChartStyle g {
    font-size: large;
}


.ChartStyle h5 {
    margin-bottom: 3px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
}

.ChartStyle p {
    padding: 10px;
    font-size: 15px;
    color: #777;
}

.ChartStyle svg {
    overflow: visible;
}

.ChartStyle .logs {
    margin-top: 0px;
}
.ActivityHeatMap h5 {
    margin-bottom: 3px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
}

.error {
    padding: 10px;
    font-size: 15px;
    color: #777;
}

.ActivityHeatMap table {
    width: 100%;
}

.ActivityHeatMap td {
    width: 50%;
}

.info {
    text-align: right;
}

.ActivityHeatMap h6 {
    margin-bottom: 3px;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: #777;
    text-align: right;
}

.react_for_echarts {
    padding-bottom: 20px;
}
.ChartLegend div {
    font-size: 13px;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
@media all and (min-width: 480px) {
    .Login {
      padding: 40px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
    
    .Login h4 {
      margin-bottom: 5px;
      margin-left: 2px;
      margin-top: 25px;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      color: #777;
    }
  }


.ServiceList {
    font-size: 13px;
    margin-left: 8px;
    margin-right: 8px;
  }

.ServiceList h4 {
    margin-bottom: 10px;
    margin-left: 2px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
}

.ServiceList table {
    width: 100%;
}

.ServiceList #services {
    font-size: 13px;
    padding: 10px;
}

.ServiceList #services td {
    padding: 3px;
    padding-left: 8px;
    word-wrap: break-word;
    max-width: 300px;
}

.TextSearch {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.text-search-button {
    height: 32px;
    font-size: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 3px;
    color: #06acd6;
}

.TextSearch #text-search-glyphicon {
    font-weight: bold;
    padding-top: 4px;
}

.ServiceList h5 {
    margin-right: 5px;
    font-family: "Open Sans", sans-serif;
    color: #777;
}

.TextSearch #text-search-filter {
    font-size: 13px;
    width: 300px;
    height: 32px;
}

.jump-to-page #page-search-filter {
    font-size: 13px;
    max-width: 100px;
    height: 32px;
    float: right;
}

.dropdown-menu {
    margin-bottom: 20px;
}

.hide-table {
    display: none;
}

.react-bootstrap-table-pagination {
    margin-right: 0px;
}

.table-bordered {
    margin-bottom: 12px;
}

.react-bootstrap-table-pagination-list {
    padding-right: 0px;
}

.react-bs-table-csv-btn {
    color: #06acd6;
    height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.pagination {
    margin-bottom: 0px;
    margin-left: 10px;
    display: flex;
}

.btn.btn-csv.active {
    color: #fff;
    background-color: #06acd6;
    border-color: #fff;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 13px;
}

.btn.btn-csv {
    color: #06acd6;
    background-color:#fff;
    border-color: #ddd;
    font-size: 13px;
}

.page-item.active a {
    background-color: #06acd6;
    border-color: #06acd6;
}

.pagination li a {
    color: #06acd6;
    background-color: #fff;
}

.text-search-left {
    display: flex;
    flex-direction: row;
}

.text-search-left h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
    color: #777;
}

.text-search-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 30px;
}

.columns-and-pagination {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;
}

.size-per-page-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.dropdown-toggle {
    padding-top: 5px;
    padding-bottom: 5px;
}

.jump-to-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
