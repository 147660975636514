.ActivityHeatMap h5 {
    margin-bottom: 3px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
}

.error {
    padding: 10px;
    font-size: 15px;
    color: #777;
}

.ActivityHeatMap table {
    width: 100%;
}

.ActivityHeatMap td {
    width: 50%;
}

.info {
    text-align: right;
}

.ActivityHeatMap h6 {
    margin-bottom: 3px;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: #777;
    text-align: right;
}

.react_for_echarts {
    padding-bottom: 20px;
}