.ServiceList {
    font-size: 13px;
    margin-left: 8px;
    margin-right: 8px;
  }

.ServiceList h4 {
    margin-bottom: 10px;
    margin-left: 2px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
}

.ServiceList table {
    width: 100%;
}

.ServiceList #services {
    font-size: 13px;
    padding: 10px;
}

.ServiceList #services td {
    padding: 3px;
    padding-left: 8px;
    word-wrap: break-word;
    max-width: 300px;
}

.TextSearch {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.text-search-button {
    height: 32px;
    font-size: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 3px;
    color: #06acd6;
}

.TextSearch #text-search-glyphicon {
    font-weight: bold;
    padding-top: 4px;
}

.ServiceList h5 {
    margin-right: 5px;
    font-family: "Open Sans", sans-serif;
    color: #777;
}

.TextSearch #text-search-filter {
    font-size: 13px;
    width: 300px;
    height: 32px;
}

.jump-to-page #page-search-filter {
    font-size: 13px;
    max-width: 100px;
    height: 32px;
    float: right;
}

.dropdown-menu {
    margin-bottom: 20px;
}

.hide-table {
    display: none;
}

.react-bootstrap-table-pagination {
    margin-right: 0px;
}

.table-bordered {
    margin-bottom: 12px;
}

.react-bootstrap-table-pagination-list {
    padding-right: 0px;
}

.react-bs-table-csv-btn {
    color: #06acd6;
    height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.pagination {
    margin-bottom: 0px;
    margin-left: 10px;
    display: flex;
}

.btn.btn-csv.active {
    color: #fff;
    background-color: #06acd6;
    border-color: #fff;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 13px;
}

.btn.btn-csv {
    color: #06acd6;
    background-color:#fff;
    border-color: #ddd;
    font-size: 13px;
}

.page-item.active a {
    background-color: #06acd6;
    border-color: #06acd6;
}

.pagination li a {
    color: #06acd6;
    background-color: #fff;
}

.text-search-left {
    display: flex;
    flex-direction: row;
}

.text-search-left h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
    color: #777;
}

.text-search-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 30px;
}

.columns-and-pagination {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;
}

.size-per-page-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.dropdown-toggle {
    padding-top: 5px;
    padding-bottom: 5px;
}

.jump-to-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}