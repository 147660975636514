.ChartStyle {
    min-height: 120px;
    margin-bottom: 5px;
}

.ChartStyle g {
    font-size: large;
}


.ChartStyle h5 {
    margin-bottom: 3px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
}

.ChartStyle p {
    padding: 10px;
    font-size: 15px;
    color: #777;
}

.ChartStyle svg {
    overflow: visible;
}

.ChartStyle .logs {
    margin-top: 0px;
}