.AccountFilter {
    font-size: 13px;
    margin-left: 8px;
  }

.AccountFilter td {
    padding: 2px;
}

.AccountFilter h4 {
    margin-bottom: 10px;
    margin-left: 2px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #777;
  }

.AccountFilter #AccountFilterTextFilter {
    max-width: 300px;
    font-size: 13px;
}