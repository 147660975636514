.App {
  margin-top: 15px;
}

.navbar {
  margin-bottom: 7px;
}

.navbar-left {
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #777;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-selected {
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #06acd6;
  padding-top: 15px;
  padding-bottom: 15px;
}

.App #loader {
  display: block;
  margin-right: 50px;
}

.service-list {
  margin-left: 50px;
}