.Dashboard .lander {
    padding: 80px 0;
    text-align: center;
  }
  
.Dashboard .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Dashboard .lander p {
  color: #999;
}

.Dashboard .ButtonToolbar {
  padding: 11px;
  margin-left: 20%;
  margin-right: 20%;
}

.dropdown-header {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #777;
}

.menu-users {
  margin-left: 10px;
}

.container {
    width: 100%;
    padding: -15px;
}
